import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment } from "react";
import DefaultHeader from "../components/DefaultHeader";
import { useTranslation } from "react-i18next";
import HeaderImg from "../pages/Imgs/SSU.png";
import { Col, Row, Container } from "react-bootstrap";

const SalesforceForm = () => {
  const [t, i18n] = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    studentId: "",
    email: "",
    phone: "",
    caseReason: "طلب تقديم التماس",
    currentMajor: "",
    desiredMajor: "",
    currentCenter: "",
    transferReason: "",
    description: "",
    subject: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.createElement("form");
    form.action =
      "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
    form.method = "POST";

    const fields = {
      orgid: "00D4L000000i6vU",
      retURL: "https://www.eelu.edu.eg/",
      name: formData.name,
      "00N4L00000A7ewg": formData.studentId,
      email: formData.email,
      phone: formData.phone,
      type: "Request",
      recordType: "0124L000000gT64",
      "00N4L000003swUg": "Other",
      "00N4L00000ASAKj": "1",
      "00N4L000003swUf": formData.caseReason,
      "00N4L00000AS0hq": formData.currentMajor,
      "00N4L00000AS0hv": formData.desiredMajor,
      "00N4L00000AS0i0": formData.currentCenter,
      "00N4L00000AS0iK": formData.transferReason,
      description: formData.description,
      subject: formData.subject,
      status: "New",
    };

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = fields[key];
        form.appendChild(input);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <Fragment>
      <DefaultHeader title={t("SSU")} backgroundImage={HeaderImg} />
      <div className="BodyDiv">
        <Container className="MainPageBodyContainer">
          <div className="BodyTitle">
            <Row>
              <Col xs={12} md={12}>
                <br />
                <div style={{ color: "rgb(17, 38, 113)", marginRight: "-3%" }}>
                  <svg
                    width="50"
                    height="35"
                    viewBox="0 0 215 259"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {/* SVG Path data */}
                  </svg>
                  التماسات
                </div>
              </Col>
            </Row>
          </div>
          <h3 style={{ marginTop: "2%", color: "rgb(17, 38, 113)" }}></h3>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={4}>
                <label htmlFor="name">أسم الطالب</label>
                <input
                  id="name"
                  maxLength="80"
                  name="name"
                  size="20"
                  type="text"
                  className="form-control"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} md={4}>
                <label htmlFor="00N4L00000A7ewg">الرقم الجامعي</label>
                <input
                  id="00N4L00000A7ewg"
                  maxLength="10"
                  name="studentId"
                  size="20"
                  type="text"
                  className="form-control"
                  placeholder="XXXXXXX (يرجي ادخال الرقم الجامعي بدون -)"
                  pattern="[0-9]{7}"
                  required
                  value={formData.studentId}
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} md={4}>
                <label htmlFor="email">البريد الإلكتروني الجامعي</label>
                <input
                  id="email"
                  maxLength="80"
                  name="email"
                  size="20"
                  type="text"
                  placeholder="xxxxxx@student.eelu.edu.eg"
                  className="form-control"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4}>
                <label htmlFor="phone">الموبايل</label>
                <input
                  id="phone"
                  maxLength="11"
                  name="phone"
                  size="20"
                  type="text"
                  className="form-control"
                  pattern="^[\u0660-\u0669\u06F0-\u06F90-9]{11}"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} md={4}>
                <label htmlFor="00N4L000003swUf">نوع الطلب</label>
                <select
                  id="00N4L000003swUf"
                  name="caseReason"
                  title="Case Reason custom"
                  className="form-control"
                  value={formData.caseReason}
                  onChange={handleChange}
                >
                  <option value="طلب تقديم التماس">طلب تقديم التماس</option>
                </select>
              </Col>
              <Col xs={12} md={4}>
                <label htmlFor="00N4L00000AS0i0">
                  المركز الدراسي المقيد به
                </label>
                <select
                  id="00N4L00000AS0i0"
                  name="currentCenter"
                  className="form-control"
                  title="Current Center"
                  required
                  value={formData.currentCenter}
                  onChange={handleChange}
                >
                  <option value="">أختر المركز</option>
                  <option value="Ain Shams">عين شمس</option>
                  <option value="Alexandria">الإسكندرية</option>
                  <option value="Alfayoum">الفيوم</option>
                  <option value="Alsadat">السادات</option>
                  <option value="Assuit">أسيوط</option>
                  <option value="Aswan">أسوان</option>
                  <option value="Beni Suef">بني سويف</option>
                  <option value="Hurghada">الغردقة</option>
                  <option value="Ismailia">الإسماعيلية</option>
                  <option value="Menoufia">المنوفية</option>
                  <option value="Qena">قنا</option>
                  <option value="Sohag">سوهاج</option>
                  <option value="Tanta">طنطا</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <label htmlFor="subject">أسم المقرر</label>
                <select
                  className="form-control"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                >
                  <option value="Software requirements Engineering ">
                    Software requirements Engineering
                  </option>
                  <option value="Research Methods in Software Engineering">
                    Research Methods in Software Engineering
                  </option>
                  <option value="Software Management ">
                    Software Management
                  </option>
                  <option value="Software Testing"> Software Testing</option>
                </select>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <label htmlFor="description">ملاحظات</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <button
                  type="submit"
                  className="btn btn-info"
                  style={{
                    backgroundColor: " rgb(17 35 97)",
                    justifyContent: "center",
                    padding: " 8px 24px",
                    borderRadius: "8px",
                    color: " #f4f5fc",
                    fontSize: "20px",
                    border: "0px",
                    marginTop: "2%",
                    marginBottom: "3%",
                  }}
                  // disabled={}
                >
                  {t("Submit")}
                </button>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </Fragment>
  );
};

export default SalesforceForm;
